export const aboutObj = {
  id: 'about',
  linkId: 'communication',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'About',
  headline: 'NICE MOVE Logistics Company',
  description:
    'was established in 2021 by a skillful team who has been in the moving business and started operation since 01 February 2021.',
  descriptionTwo:
    'We are able to provide a high standard of moving service in Mongolia. ',
  telephone:
    'Nice Move Logistics would greatly appreciate your inquiry for any relocations you may have to any part of the world.',
  aboutText:
    'If you require quality, competitive rates & a smooth relocation service in Mongolia please do not hesitate to contact us and request for a quotation',
  aboutTextTwo: `Rest assured of our best service at all times. 
Our promises to Clients and Partners:

  COMMUNICATION	NICE
  COOPERATION           NICE
  SERVICE		    	NICE
                                        NICE MOVE
                                        NICE LOGISTICS`,
  buttonLabel: '',
  imgStart: false,
  img: require('../../images/svg-4.svg').default,
  alt: 'image',
  dark: true,
  primary: false,
  darkText: false,
};

export const commObj = {
  id: '',
  linkId: '',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Communication NICE',
  description: '',
  buttonLabel: '',
  imgStart: true,
  img: require('../../images/svg-2.svg').default,
  alt: 'image',
  dark: true,
  primary: false,
  darkText: false,
};

export const coopObj = {
  id: '',
  linkId: '',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Cooperation NICE',
  description: '',
  buttonLabel: '',
  imgStart: false,
  img: require('../../images/svg-16.svg').default,
  alt: 'image',
  dark: true,
  primary: true,
  darkText: true,
};

export const serveObj = {
  id: '',
  linkId: '',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Service NICE',
  description: '',
  buttonLabel: '',
  imgStart: true,
  img: require('../../images/png-17.png').default,
  alt: 'image',
  dark: true,
  primary: true,
  darkText: false,
};

export const niceObj = {
  id: '',
  linkId: '',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'NICE Move',
  headlineTwo: 'NICE LOGISTICS',
  description: '',
  buttonLabel: '',
  generalInq: undefined,
  rateInq: undefined,
  imgStart: false,
  img: require('../../images/svg-5.svg').default,
  alt: 'image',
  dark: true,
  primary: true,
  darkText: true,
};

export const contactObj = {
  id: 'contact',
  linkId: 'about',
  onclick: window.scrollTo(0, 0),
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Contacts',
  description: 'Nice Move Logistics LLC',
  descriptionTwo: 'Address : 1510, Peace Tower,',
  descriptionThree: 'Peace Avenue 54, Ulaanbaatar, 15172, Mongolia',
  telephone: 'Tel: +976-77348989',
  generalInq: 'cooperation@nicelogistics.mn',
  rateInq: 'move@nicelogistics.mn',
  accountPay: 'accounting@nicelogistics.mn',
  serviceQ: 'service@nicelogistics.mn',
  buttonLabel: '',
  imgStart: false,
  img: require('../../images/svg-14.svg').default,
  alt: 'image',
  dark: true,
  primary: true,
  darkText: true,
};
