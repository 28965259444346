import React, { useState, useEffect } from 'react';
import { Footer } from '../components/Footer';
import HeroSection from '../components/HeroSection';
import { InfoSection } from '../components/InfoSection';
import {
  aboutObj,
  commObj,
  contactObj,
  coopObj,
  serveObj,
  niceObj,
} from '../components/InfoSection/Data';
import { Navbar } from '../components/Navbar';
import { News } from '../components/News';
import { Services } from '../components/Services';
import { Sidebar } from '../components/Sidebar';
import { ScrollToTop } from '../components/ScrollToTop';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Home = () => {
  const { height, width } = useWindowDimensions();

  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  window.addEventListener('scroll', toggleVisible);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  let text = `Rest assured of our best service at all times. Our promises to Clients and Partners 
  
  COMMUNICATION		NICE
  COOPERATION 		NICE
  SERVICE		    	NICE
                  NICE MOVE 	=	NICE LOGISTICS`;
  let str = text.replace(/\n/g, '<br>');

  const GetFooterPos = () => {
    const footer = document.querySelector('Footer');
    const footerData = footer.getBoundingClientRect();
    const footerPos = height - footerData.top;

    console.log(footerPos + 'px');
    console.log(document.querySelector('div.ScrollToTop'));

    if (footerPos > 0)
      document.querySelector('div.ScrollToTop').style.marginBottom =
        footerPos + 'px';
  };

  useEffect(() => {
    window.addEventListener('scroll', GetFooterPos);
    return () => {
      window.removeEventListener('scroll', GetFooterPos);
    };
  });

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...commObj} />
      <InfoSection {...coopObj} />
      <InfoSection {...serveObj} />
      <InfoSection {...niceObj} />
      <InfoSection {...aboutObj} motto={str} />
      <Services />
      <News />
      <InfoSection {...contactObj} />

      <ScrollToTop />

      <Footer />
    </>
  );
};

export default Home;
