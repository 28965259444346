import styled from 'styled-components';

export const PopupContainer = styled.div`
  width: 800px;
  height: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  color: #000;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 480px;
  }

  @media screen and (max-width: 480px) {
    width: 350px;
  }
`;

export const PopupWrapper = styled.div`
  color: #fff;
  position: relative;
  padding: 10px;
`;
