import React from 'react';
import Icon11 from '../../images/svg-11.svg';
import Icon4 from '../../images/svg-4.svg';
import Icon5 from '../../images/svg-5.svg';
import Icon6 from '../../images/svg-6.svg';
import Icon7 from '../../images/svg-7.svg';
import Icon8 from '../../images/svg-8.svg';
import Icon9 from '../../images/svg-9.svg';
import Icon10 from '../../images/svg-10.svg';
import Icon13 from '../../images/svg-13.svg';
import Icon12 from '../../images/svg-12.svg';
import {
  ServicesCard,
  ServicesContainer,
  ServicesH1,
  ServicesH2,
  ServicesIcon,
  ServicesWrapper,
} from './ServicesElements';


export const Services = () => {
  return (
    <ServicesContainer id="service">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>Professional Packing</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon5} />
          <ServicesH2>International Moving</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon6} />
          <ServicesH2>Domestic & Local Moves</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon7} />
          <ServicesH2>Sea & Air Shipments</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon8} />
          <ServicesH2>Office Moves</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon9} />
          <ServicesH2>Pet Relocations</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon10} />
          <ServicesH2>Car Handling</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon11} />
          <ServicesH2>Customs Clearance</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon12} />
          <ServicesH2>Warehousing</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon13} />
          <ServicesH2>International Freight forwarding</ServicesH2>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};
