import React, { useState, useEffect } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import { ToTheTopBtn } from './ToTheTopBtn';

export const ScrollToTop = () => {
  return (
    <div
      class="ScrollToTop"
      style={{ position: 'fixed', bottom: '60px', right: '60px' }}
    >
      <ToTheTopBtn
        primary="true"
        dark="true"
        className=""
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <FaArrowCircleUp />
      </ToTheTopBtn>
    </div>
  );
};
