import React from 'react';
import { HeroContainer, HeroBg, ImageCont } from './HeroElements';
import LogoTest from '../../images/logo/logoTest.png';

const HeroSection = () => {
  /* let rotateValueHolder = new Animated.Value(0);

  const startImageRotateFunction = () => {
    rotateValueHolder.setValue(0);
    Animated.timing(rotateValueHolder, {
      toValue: 1,
      duration: 3000,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start(() => startImageRotateFunction());
  };

  const rotateData = rotateValueHolder.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });
  useEffect(() => {
    startImageRotateFunction();
  }); */

  return (
    <HeroContainer>
      <HeroBg>
        <ImageCont src={LogoTest} alt="Logo NML" />
      </HeroBg>
    </HeroContainer>
  );
};
export default HeroSection;
