import React, { useState, useEffect } from 'react';
import NewsIcon from '../../images/svg-15.svg';
import {
  Column1,
  Column2,
  Img,
  ImgWrap,
  NewsContainer,
  InfoRow,
  InfoWrapper,
  TextWrapper,
} from './NewsElements';
// import './data.json';
import './index.css';
import Popup from './Popup';

export const News = () => {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(() => !isOpen);
  };

  const getData = () => {
    fetch('./data.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        setData(() => myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <NewsContainer id="news">
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
                {data &&
                  data.length > 0 &&
                  data.map((item) => (
                    <p>
                      <h1 onClick={togglePopup}>{item.title}</h1>
                      <br />
                      <div>
                        {isOpen && (
                          <Popup
                            content={
                              <>
                                <h2>{item.title}</h2>
                                <br />
                                {item.publishDate}
                                <br />
                                <br />
                                {item.content}
                              </>
                            }
                            handleClose={togglePopup}
                          />
                        )}
                      </div>
                    </p>
                  ))}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={NewsIcon} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </NewsContainer>
    </>
  );
};
