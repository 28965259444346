import React from 'react';
import {
  FooterContainer,
  FooterWrap,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from './FooterElements';

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              Nice
            </SocialLogo>
            <WebsiteRights>
              Nice Move Logistics LLC © {new Date().getFullYear()} All rights
              reserved.
            </WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};
