import React from 'react';
import {
  Column1,
  Column2,
  Heading,
  Img,
  ImgTwo,
  ImgWrap,
  InfoContainer,
  InfoRow,
  InfoWrapper,
  Subtitle,
  TextWrapper,
  TopLine,
} from './InfoElements';
const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};
export const InfoSection = ({
  lightBg,
  linkId,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  headlineTwo,
  darkText,
  description,
  descriptionTwo,
  descriptionThree,
  descriptionFour,
  aboutText,
  aboutTextTwo,
  telephone,
  generalInq,
  serviceQ,
  rateInq,
  accountPay,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  motto,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Heading lightText={lightText}>{headlineTwo}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Subtitle darkText={darkText}>{descriptionTwo}</Subtitle>
                <Subtitle darkText={darkText}>{descriptionThree}</Subtitle>
                <Subtitle darkText={darkText}>{descriptionFour}</Subtitle>
                <Subtitle darkText={darkText}>{telephone}</Subtitle>
                <Subtitle darkText={darkText}>{aboutText}</Subtitle>
                <Subtitle darkText={darkText}>{aboutTextTwo}</Subtitle>
                {generalInq !== undefined && rateInq !== undefined ? (
                  <>
                    {' '}
                    <Subtitle darkText={darkText}>
                      General Inquiries:{' '}
                      <Mailto
                        email="cooperation@nicelogistics.mn"
                        subject="Cooperation"
                        body="Hello, I'm inquiring about a cooperation."
                      >
                        {generalInq}
                      </Mailto>
                    </Subtitle>
                    <Subtitle darkText={darkText}>
                      Rate requests:{' '}
                      <Mailto
                        email="move@nicelogistics.mn"
                        subject="Move"
                        body="Hello, I'm inquiring about a move."
                      >
                        {rateInq}
                      </Mailto>
                    </Subtitle>
                    <Subtitle darkText={darkText}>
                      Accounts Payable:{' '}
                      <Mailto
                        email="accounting@nicelogistics.mn"
                        subject="Account Payables"
                        body="Hello, I'm inquiring about a an account payable."
                      >
                        {accountPay}
                      </Mailto>
                    </Subtitle>
                    <Subtitle darkText={darkText}>
                      Service quality:{' '}
                      <Mailto
                        email="service@nicelogistics.mn"
                        subject="Service Quality"
                        body="Hello, I'm inquiring about a case for service quality."
                      >
                        {serviceQ}
                      </Mailto>
                    </Subtitle>{' '}
                  </>
                ) : (
                  <div></div>
                )}
              </TextWrapper>
            </Column1>
            <Column2>
              {aboutTextTwo && aboutText ? (
                <><ImgWrap>
                <ImgTwo src={img} alt={alt} />
              </ImgWrap></>
              ) : (
                <ImgWrap>
                  <Img src={img} alt={alt} />
                </ImgWrap>
              )}
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};
