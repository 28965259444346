import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Popup from 'reactjs-popup';
import { Gallery } from '../Gallery';
import { PopupContainer, PopupWrapper } from './PopupElements';

export const PopupEl = forwardRef((props, reff) => {
  useImperativeHandle(reff, () => ({
    open() {
      ref.current.open();
    },
  }));
  const ref = useRef();
  return (
    <Popup ref={ref} modal nested>
      {(close) => (
        <PopupContainer>
          <PopupWrapper>
            <Gallery />
          </PopupWrapper>
        </PopupContainer>
      )}
    </Popup>
  );
});
