import React, { useRef } from 'react';
import { FaBars } from 'react-icons/fa';
import { PopupEl } from '../PopupEl';
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
} from './NavbarElements';

export const Navbar = ({ toggle }) => {
  const ref = useRef();
  const openPopup = () => {
    ref.current.open();
  };
  return (
    <Nav>
      <NavbarContainer>
        <NavLogo
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          Nice Move Logistics LLC
        </NavLogo>
        <MobileIcon onClick={toggle}>
          <FaBars />
        </MobileIcon>
        <NavMenu>
          <NavItem>
            <NavLinks
              to="about"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              About
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              to="service"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Service
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              to="news"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              News
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              to="contact"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Contact
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              onClick={openPopup}
            >
              Gallery
            </NavLinks>
          </NavItem>
        </NavMenu>
        <PopupEl ref={ref} />
      </NavbarContainer>
    </Nav>
  );
};
