import styled from 'styled-components';
import { Link } from 'react-scroll';

export const ToTheTopBtn = styled(Link)`
  position: fixed;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #fc6c0f;
`;
