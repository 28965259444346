import React, { useRef } from 'react';
import { PopupEl } from '../PopupEl';
import {
  CloseIcon,
  Icon,
  SidebarContainer,
  SidebarLink,
  SidebarMenu,
  SidebarWrapper,
} from './SidebarElements';

export const Sidebar = ({ isOpen, toggle }) => {
  const ref = useRef();
  const openPopup = () => {
    ref.current.open();
  };
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="about" onClick={toggle}>
            About
          </SidebarLink>
          <SidebarLink to="service" onClick={toggle}>
            Service
          </SidebarLink>
          <SidebarLink to="news" onClick={toggle}>
            News
          </SidebarLink>
          <SidebarLink to="contact" onClick={toggle}>
            Contact
          </SidebarLink>
          <SidebarLink onClick={openPopup}>Gallery</SidebarLink>
          <PopupEl ref={ref} />
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};
